/*----------------------
    Blog Styles  
-----------------------*/
.rn-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--color-blackest);
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: var(--radius);
    overflow: hidden;
    transition: all .5s;

    .inner {
        .thumbnail {
            a {
                img {
                    width: 100%;
                }
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
            padding: 30px;
            padding-top: 22px;
            
            @media #{$sm-layout} {
                padding: 30px 20px;
            }
            .descriptiion {
                margin-bottom: 0;
                margin-bottom: 13px;
            }
            .title {
                font-size: 20px;
                line-height: 1.5;
                transition: all .5s;
                margin-bottom: 0;
                margin-top: 15px;
                flex-basis: 100%;
            }
        }

    }

    &.card-list-view { 
        height: 100%;
        .inner {
            display: flex;
            height: 100%;

            .thumbnail {
                flex-basis: 50%;
                a {
                    display: block;
                    height: 100%;
                    img {
                        width: auto;
                        object-fit: cover;
                        height: 100%;
                    }
                }
            }
            .content {
                flex-basis: 50%;
                .title {
                    margin-bottom: 10px;
                }
                .descriptiion {
                    margin-bottom: 13px;
                    margin-top: 12px;
                }
            }
        }
    }
}


.rn-meta-list {
    @extend %liststyle;
    font-size: 1.4rem;
    line-height: 2.2rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
        margin-top: 0;
        margin-bottom: 0;
        color: var(--color-gray);
        font-size: 14px;
        

        a {
            color: var(--color-gray);
            transition: 0.5s;
        }

        &.separator {
            margin: 0 .8rem;
        }
    }
}