/*----------------------------
    Video Popup Styles  
------------------------------*/
.video-popup ,
.rn-gallery {
    position: relative;
    display: block;
    .thumbnail {
        img {
            width: 100%;
        }
    }
    .video-icon {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: 10px;
        transition: 0.4s;
    }
}
.rn-gallery {
    .video-icon {
        opacity: 0;
        transition: 0.4s;
        transform: translate(-50%, -50%) scale(0.5);
    }
    &:hover {
        .video-icon {
            opacity: 1;
            margin-top: 0;
            transform: translate(-50%, -50%) scale(1);
        }
    }
}

.video-gallery-content {
    border-radius: 10px;
    padding-top: 300px;
    padding-bottom: 60px;
    overflow: hidden;

    @media #{$md-layout} {
        padding-bottom: 40px;
        padding-top: 150px;
    }

    @media #{$sm-layout} {
        padding-bottom: 40px;
        padding-top: 150px;
    }

    .overlay-content {
        padding: 0 60px;

        @media #{$sm-layout} {
            padding: 0 20px;
        }
        .title {
            margin-top: 15px;
            margin-bottom: 0;
            font-size: 30px;
            letter-spacing: 0.5px;
            line-height: 1.4;
            color: var(--color-white);
            @media #{$sm-layout} {
                font-size: 20px;
            }
        }
    }
}

