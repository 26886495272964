.slick-arrow {
  background-color: var(--color-secondary) !important; 
  border: 1px solid #b9b9b9;
  border-radius: 4px;
}

.slick-prev {
    right: auto;
    left: 20px; /* Adjust the arrow position for RTL */
  }
  
  .slick-next {
    left: auto;
    right: 20px; /* Adjust the arrow position for RTL */
  }